import React from 'react';
import Dropdown from '../../Dropdown';
import { ChannelType, ThreadType } from '../../../libraries/Models';
import { useSocket } from '../../../wsprovider';
import { ChannelProcesses } from '../header/ChannelSettings';

export type InfoBlockMainViewType = {
  className?: string;
  currentThread?: ThreadType;
  channel?: ChannelType;
};

const InfoBlockMainView: React.FC<InfoBlockMainViewType> = ({ className, currentThread, channel }) => {
  const { updateThreadStatusAction } = useSocket();

  const handleSelectStatus = (selectedId: string) => {
    if (currentThread?.id && channel?.team_id) {
      updateThreadStatusAction(currentThread.id, selectedId, channel.team_id);
    }
  };

  return (
    <div className={`h-[90vh] overflow-y-scroll overflow-x-hidden bg-[#d9d9d9]/30 flex flex-col min-w-[100] max-w-[450px] justify-start items-center ${className}`}>
      {channel?.is_process && JSON.parse(channel?.settings || '{}').processes.length > 0 && (
        <div className="flex w-full h-[53px] mt-3 mb-3">
          <div className="flex flex-row h-full w-full bg-white mx-3 items-center px-2 gap-5">
            Статус
            <Dropdown
              options={
                (JSON.parse(channel?.settings || '{}').processes as ChannelProcesses[])?.map(item => ({
                  id: item.id,
                  name: item.name,
                  color: item.color
                })) || []
              }
              selectedId={currentThread?.status || ''}
              onSelect={handleSelectStatus}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default InfoBlockMainView;
