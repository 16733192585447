import React, { useCallback, useEffect, useMemo, useState, useRef } from "react";
import { useSocket } from "../../../wsprovider";
import useLocalStorage from "../../../localstorage";
import { ThreadType, UserType, ResponseModel } from "../../../libraries/Models";
import { useNavigate, useLocation } from "react-router-dom";

export type ThreadsMainViewType = {
    className?: string;
    onClick?: (threadId?: string) => void;
};

const ThreadsMainView: React.FC<ThreadsMainViewType> = React.memo(({ className, onClick }) => {
    // const [users] = useLocalStorage<UserType[]>("users", []);
    const [threads, setThreads] = useState<ThreadType[]>([]);
    const [offset, setOffset] = useState<number>(0);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const { sockets, newThread, users } = useSocket();
    const navigate = useNavigate();
    const location = useLocation();
    const containerRef = useRef<HTMLDivElement | null>(null);
    const initialLoadComplete = useRef(false);

    const [teamId, channelId, threadId] = useMemo(() => {
        const segments = location.pathname.split("/").filter(Boolean);
        return [segments[1], segments[2], segments[3]];
    }, [location.pathname]);

    const loadThreads = useCallback((isFirst?: boolean) => {
        console.log('load thread: ' + threads.length, offset, isFetching, !hasMore)
        if ((isFetching || !hasMore) && !isFirst) return;
        setIsFetching(true);
        sockets?.[teamId || ""]?.emit(
            "get_threads",
            Number(channelId),
            { limit: 20, skip: isFirst ? 0 : offset },
            (response: ResponseModel) => {
                setIsFetching(false);
                if (!response.success || !response.threads) {
                    setHasMore(false); // Завершаем загрузку, если ответ некорректен
                    return;
                }

                setThreads((prevThreads) => {
                    const newThreads = Array.from(new Set([...prevThreads, ...response.threads!]));
                    if (response.threads!.length < 20) {
                        setHasMore(false);
                    }
                    setOffset(newThreads.length);
                    return newThreads;
                });
            }
        );
    }, [isFetching, hasMore, sockets, teamId, channelId, offset]);
    useEffect(() => {
        if (!newThread) return
        setThreads(prev => [newThread, ...prev])
    }, [newThread])
    const prevChannelId = useRef<string | undefined>();
    useEffect(() => {
        if (prevChannelId.current !== channelId && channelId) {
            prevChannelId.current = channelId; // Обновляем предыдущее значение
            setThreads([]);
            setOffset(0);
            setHasMore(true);
            setIsFetching(false)
            initialLoadComplete.current = false; // Обновляем флаг, чтобы позволить следующему запросу выполниться
            loadThreads(true); // Загружаем треды для нового канала
        }
    }, [channelId, loadThreads]);
   
    useEffect(() => {
        const handleScroll = () => {
            if (!containerRef.current || isFetching || !hasMore) return;

            const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
            if (scrollTop + clientHeight >= scrollHeight - 50) {
                loadThreads(); // Загружаем больше тредов при достижении конца контейнера
            }
        };

        const currentRef = containerRef.current;
        currentRef?.addEventListener("scroll", handleScroll);

        return () => {
            currentRef?.removeEventListener("scroll", handleScroll);
        };
    }, [loadThreads, isFetching, hasMore]);

    const clickToThreadItem = (threadId?: string) => {
        if (!threadId || !teamId || !channelId) return;
        navigate(`/workspace/${teamId}/${channelId}/${threadId}`);
        onClick!(threadId)
    };

    return (
        <div
            ref={containerRef}
            className={`flex h-[90vh] overflow-y-scroll overflow-x-hidden flex-col bg-white mt-0 ${className}`}
        >
            {threads.map((thread, index) => {
                const isSelected = threadId === String(thread.id);
                return (
                    <div
                        id={String(thread.id)}
                        key={thread.id}
                        onClick={() => clickToThreadItem(String(thread.id))}
                        className={`flex h-[77px] w-full pl-2 ${index === 0 ? "mt-0" : ""} ${isSelected ? 'bg-[#4E4963]/90' : 'hover:bg-[#EFEEF5]/40'}`}
                    >
                        {thread.last_message_created_at ? (
                            <div className="flex flex-1">
                                <div className="flex w-[77px] h-full items-center justify-center">
                                    <img className="m-3 rounded-full" alt="User Avatar" width={50} height={50} src={'/emptyAvatar.png'} />
                                </div>
                                <div className="flex flex-1 flex-row">
                                    <div className="flex w-full flex-col">
                                        <div className="flex flex-row m-3">
                                            <div className={`flex flex-1 text-[18px] font-medium ${isSelected ? "text-white" : "text-[#4E4963]"}`}>
                                                {users?.find((user) => Number(user.id) === Number(thread.last_message_user_id))?.fullname || users?.find((user) => Number(user.id) === Number(thread.last_message_user_id))?.nicname || "Unknown User"}
                                            </div>
                                            <div className={`flex min-w-3 h-3 items-center justify-center bg-[#75C875] rounded-full ${thread.is_unread ? "" : "hidden"}`}></div>
                                        </div>
                                        <div className={`w-[250px] ml-3 truncate text-[17px] font-light ${isSelected ? "text-white" : "text-[#4E4963]"}`}>
                                            {thread.last_message_text}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className={`flex h-[77px] justify-start items-center text-[17px] font-bold ${isSelected ? "text-white" : "text-[#4E4963]"}`}>
                                {thread.title}
                            </div>
                        )}
                    </div>
                );
            })}
        </div>
    );
});

export default ThreadsMainView;
