import SidebarMainView from "./components/chat_components/sidebar/Sidebar_main_view";
import ThreadsMainView from "./components/chat_components/thread/Threads_main_view";
import ChatMainView from "./components/chat_components/chat/Chat_main_view";
import InfoBlockMainView from "./components/chat_components/InfoBlock/InfoBlock_main_view";
import HeaderMainView from "./components/chat_components/header/Header_main_view";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useSocket } from "./wsprovider";
import { ChannelType, MessageType, ThreadType } from "./libraries/Models";
import { useLocation, useNavigate } from "react-router-dom";

const Workspace: FC = () => {
    console.log('Rendering Workspace component');
    const location = useLocation();
    const [teamId, channelId, threadId] = useMemo(() => {
        const segments = location.pathname.split('/').filter(Boolean);
        return [segments[1], segments[2], segments[3]];
    }, [location.pathname]);

    const [showConversations, setShowConversations] = useState(false);
    const { sockets, currentThread, channels, threads, newMessage, messages, readThread } = useSocket();
    const navigate = useNavigate();
    const [message, setMessage] = useState<MessageType | undefined>(undefined);
    const [isHiddenThreadInfo, setHiddenThreadInfo] = useState(false);
    const [showContainer3, setShowContainer3] = useState(false);
    const [showContainer4, setShowContainer4] = useState(false);

    // useEffect(() => {
    //     const savedShowContainer3 = localStorage.getItem('showContainer3');
    //     const savedShowContainer4 = localStorage.getItem('showContainer4');
        
    //     if (savedShowContainer3 !== null) setShowContainer3(JSON.parse(savedShowContainer3));
    //     if (savedShowContainer4 !== null) setShowContainer4(JSON.parse(savedShowContainer4));
    // }, []);

    useEffect(() => {
        localStorage.setItem('showContainer3', JSON.stringify(showContainer3));
    }, [showContainer3]);

    useEffect(() => {
        localStorage.setItem('showContainer4', JSON.stringify(showContainer4));
    }, [showContainer4]);

    const onBackClick = (id?: number) => {
        if (id) {
            setShowContainer3(false);
            return;
        }
        setShowContainer3(false);
    };
    const handleNavigation = (path: string) => {
      navigate(path, { replace: true });
    };
  
    const clickToSidebarItem = (id: number, teamId?: string) => {
        if (id === -1 || !teamId) {
            return;
        }
        const newPath = `/workspace/${teamId}/${id}/`;
        handleNavigation(newPath)
        setShowContainer3(false);
        setShowContainer4(false);
    };

    const clickToThreadItem = (threadId?: string) => {
        setShowContainer3(true);
        readThread(threadId!);
    };

    const onSendMessage = (message: MessageType) => {
        setMessage(message);
    };
    console.log(showContainer3)
    return (
        <div className="flex flex-col h-screen w-screen overflow-x-hidden overflow-y-hidden">
            <div className="flex h-[70px]">
                <HeaderMainView
                    threadId={threadId}
                    channelId={channelId}
                    teamId={teamId ? teamId.toString() : ''}
                    setShowContainer3={setShowContainer3}
                    setShowContainer4={setShowContainer4}
                    showContainer3={showContainer3}
                    showContainer4={showContainer4}
                    onClick={onBackClick}
                    selectedSidebarItemId={Number(channelId)}
                    hideThreadInfo={setHiddenThreadInfo}
                    currentThreadTitle={currentThread?.title}
                    showConversations={showConversations}
                />
            </div>
            
            <div className="flex flex-grow flex-1">
                <div className="w-[94px] h-full max-lg:mt-[70px]">
                    <SidebarMainView
                        currentId={Number(channelId)}
                        clickToSidebarItem={clickToSidebarItem}
                        channels={channels}
                    />
                </div>
                {!channelId && (
                    <div className="flex flex-1 justify-center items-center text-[#4e4963] text-[17px] font-light font-['Roboto']">
                        Выберите канал и приступайте к работе!
                    </div>
                )}
                {channelId && (
                    <div className="min-w-[400px] h-full max-lg:mt-[70px]">
                        <ThreadsMainView
                            onClick={clickToThreadItem}
                            
                        />
                    </div>
                )}
                {threadId && (
                    <div
                        className={`max-lg:pt-[70px] max-lg:h-[90vh] lg:h-full transition-all duration-300 ${showContainer4 ? 'lg:min-w-[500px] lg:flex-1' : 'lg:flex-grow'}
                        ${showContainer3 ? 'max-lg:fixed max-lg:inset-0 max-lg:z-10' : ''}`} // Условие для перекрытия на маленьком экране
                    >
                        <ChatMainView
                            allMessages={messages}
                            ext_thread_id={currentThread?.ext_thread_id}
                            threadId={String(currentThread?.ext_thread_id || currentThread?.id)}
                            teamId={channels?.find(item => String(item.id) === channelId)?.team_id}
                            incomingMessage={newMessage}
                            onSendMessage={onSendMessage}
                            showInMobile={showContainer3}
                        />
                    </div>
                )}
                {channelId && threadId && showContainer4 && (
                    <div className={`lg:w-[300px] h-full ${showContainer4 ? 'max-lg:fixed max-lg:inset-0 max-lg:z-20 max-lg:mt-[70px] bg-white' : ''}`}>
                        <InfoBlockMainView
                            currentThread={currentThread}
                            channel={channels?.find(item => String(item.id) === channelId)}
                        />
                    </div>
                )}
            </div>
            
        </div>
    );
};

export default Workspace;
