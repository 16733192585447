import React, { useEffect, useState } from 'react';
import { Flip, toast } from 'react-toastify';
import { useSocket } from '../../../wsprovider';

export type ProfileEditorType = {
  teamId: string;
};

const ProfileEditor: React.FC<ProfileEditorType> = ({ teamId }) => {
  const [fullname, setFullname] = useState('');
  const [nickname, setNickname] = useState('');
  const [bio, setBio] = useState('');
  const { users, decodedTokens, updateUserInfoAction } = useSocket();
  const [avatar, setAvatar] = useState('https://i.pravatar.cc/150?img=1');

  useEffect(() => {
    const userId = decodedTokens?.[teamId]?.user_id;
    const user = users?.find((item) => item.id === userId);
    if (!user) {
      return;
    }
    setNickname(user.nicname);
    setFullname(user.fullname);
    setBio(user.bio);
  }, [users, decodedTokens, teamId]);

  // Функция для обработки изменения аватара
  const handleAvatarChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        setAvatar(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  // Функция для обработки сохранения профиля
  const handleSave = () => {
    updateUserInfoAction({ fullname, nicname: nickname, bio }, teamId);
    toast.success('User updated', {
      position: 'top-center',
      theme: 'colored',
      transition: Flip,
    });
  };

  return (
    <div className="max-w-lg mx-auto p-6 bg-white">
      <h2 className="text-2xl font-semibold mb-4 text-gray-800">Edit Profile</h2>

      {/* Аватар */}
      <div className="mb-6 flex items-center">
        <img src={avatar} alt="Avatar" className="w-20 h-20 rounded-full mr-4 object-cover" />
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Change Avatar</label>
          <input
            type="file"
            accept="image/*"
            onChange={handleAvatarChange}
            className="text-sm text-gray-500 file:mr-2 file:py-1 file:px-4 file:rounded-full file:border-0 file:text-sm file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
          />
        </div>
      </div>

      {/* Fullname */}
      <div className="mb-6">
        <label htmlFor="fullname" className="block text-sm font-medium text-gray-700">
          Fullname
        </label>
        <input
          type="text"
          id="fullname"
          value={fullname}
          onChange={(e) => setFullname(e.target.value)}
          className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
        />
      </div>

      {/* Nickname */}
      <div className="mb-6">
        <label htmlFor="nickname" className="block text-sm font-medium text-gray-700">
          Nickname
        </label>
        <input
          type="text"
          id="nickname"
          value={nickname}
          onChange={(e) => setNickname(e.target.value)}
          className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
        />
      </div>

      {/* Bio */}
      <div className="mb-6">
        <label htmlFor="bio" className="block text-sm font-medium text-gray-700">
          Bio
        </label>
        <textarea
          id="bio"
          value={bio}
          onChange={(e) => setBio(e.target.value)}
          className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          rows={3}
        ></textarea>
      </div>

      {/* Save Button */}
      <div className="text-right">
        <button
          onClick={handleSave}
          className="px-4 py-2 bg-blue-600 text-white text-sm font-medium rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default ProfileEditor;
