import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate, useSearchParams } from 'react-router-dom';
import Workspace from './workspace';
import { SocketProvider, useSocket } from './wsprovider';
import { ToastContainer } from 'react-toastify';

function AppContent() {
  const [isLoading, setIsLoading] = useState(true);
  const { loading: socketsLoading } = useSocket(); // Получение состояния загрузки из сокетов
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const tokens = searchParams.get('tokens');

  function base64UrlDecode(str: string) {
    str = str.replace(/-/g, '+').replace(/_/g, '/');
    const binaryString = atob(str);
    const bytes = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return new TextDecoder('utf-8').decode(bytes);
  }

  function getCookie(name: string) {
    const matches = document.cookie.match(new RegExp(
      "(?:^|; )" + name.replace(/([$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  useEffect(() => {
    if (window.location.pathname === '/') {
      const localTokens = localStorage.getItem('authTokens');
      if (tokens) {
        try {
          const decodedJsonString = base64UrlDecode(decodeURIComponent(tokens));
          localStorage.setItem('authTokens', decodedJsonString);
          navigate(`/workspace`);
          setIsLoading(false);
          return;
        } catch (error) {
          console.error('Error decoding token:', error);
        }
      }

      if (localTokens) {
        navigate(`/workspace`);
        setIsLoading(false);
        return;
      }

      window.location.href = 'https://auth.pbx.team';
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }, [tokens, navigate]);

  if (isLoading || socketsLoading) {
    return (
      <div className="w-full h-screen flex items-center justify-center">
        Loading...
      </div>
    );
  }

  return (
    <Routes>
      <Route path="/workspace/*" element={<Workspace />} />
    </Routes>
  );
}

function App() {
  return (
    <Router>
      <SocketProvider>
        <AppContent />
      </SocketProvider>
      <ToastContainer />
    </Router>
  );
}

export default App;
