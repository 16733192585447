import React from 'react';

interface ColorPickerProps {
  color: string;
  onChange: (newColor: string) => void;
}

const ColorPicker: React.FC<ColorPickerProps> = ({ color, onChange }) => {
  const handleColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <div className="relative">
      <input
        type="color"
        value={color}
        onChange={handleColorChange}
        className="absolute opacity-0 w-3 h-3"
      />
      <div
        className="w-3 h-3 rounded-full"
        style={{ backgroundColor: color, cursor: 'pointer' }}
      ></div>
    </div>
  );
};

export default ColorPicker;
