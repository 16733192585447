import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

interface ChatMessageProps {
  id: string;
  img?: string;
  username: string;
  message: string;
  senderId: number;
  prevSenderId?: number;
  showAvatar: boolean;
  timestamp: number;
}

const CodeBlock: React.FC<{ inline?: boolean; className?: string; children: React.ReactNode }> = ({
  inline,
  className,
  children,
  ...props
}) => {
  const match = /language-(\w+)/.exec(className || '');
  return !inline && match ? (
    <SyntaxHighlighter style={materialDark} language={match[1]} PreTag="div" {...props}>
      {String(children).replace(/\n$/, '')}
    </SyntaxHighlighter>
  ) : (
    <code className={className + "break-words"} {...props}>
      {children}
    </code>
  );
};

const ChatMessage: React.FC<ChatMessageProps> = ({
  id,
  img,
  username,
  message,
  senderId,
  prevSenderId,
  showAvatar,
  timestamp,
}) => {
  const myId = 1;

  // Форматируем время в hh:mm
  const formattedTime = new Date(timestamp).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  });

  return (
    <div
      id={id}
      className={`flex items-end ${showAvatar ? 'mb-10' : ''} ${
        senderId === 1 || senderId === 2 ? 'justify-end' : 'justify-start'
      }`}
    >
      {/* Аватарка слева, если senderId не равен myId */}
      {showAvatar && img && (senderId !== 1 && senderId !== 2) && (
        <div className="flex flex-col items-center mr-2 ml-2 self-end">
          <img
            width={45}
            height={45}
            src={img!}
            alt={username}
            className="w-[45px] h-[45px] rounded-full"
          />
        </div>
      )}
      <div 
  className={`relative max-lg:max-w-[70vw] lg:max-w-[35vw] min-w-28 ${
    senderId === prevSenderId ? 'bg-[#F7F7FA] text-[#4E4963]' : 'bg-[#F7F7FA]'
  } ${!showAvatar ? 'ml-14' : ''} 
      rounded-[10px] px-3 py-1 mb-2 flex flex-col justify-end`}
>
  <div className="message-content whitespace-pre-wrap break-words">
    <ReactMarkdown
      className="overflow-x-auto text-ellipsis break-words"
      components={{
        code({ inline, className, children, ...props }: any) {
          return (
            <CodeBlock inline={inline} className={className} {...props}>
              {children}
            </CodeBlock>
          );
        },
      }}
    >
      {message}
    </ReactMarkdown>
  </div>

  {/* Время, прикрепленное к нижнему краю сообщения */}
  <span
    className="mt-0 text-[10px] font-normal font-['Roboto'] text-[#8D84B0] self-end whitespace-nowrap"
  >
    {formattedTime}
  </span>

  {/* Ник пользователя под контейнером сообщения */}
  {showAvatar && img && (
    <div className={`absolute -bottom-6 ${senderId === 1 || senderId === 2 ? 'left-0' : 'right-0'} text-[#8D84B0] text-[12px] font-semibold font-['Roboto']`}>
      {username}
    </div>
  )}
</div>
      {/* Аватарка справа, если senderId === myId */}
      {showAvatar && img && (senderId === 1 || senderId === 2) && (
        <div className="flex flex-col items-center ml-2 mr-2 self-end">
          <img
            width={45}
            height={45}
            src={img!}
            alt={username}
            className="w-[45px] h-[45px] rounded-full"
          />
        </div>
      )}
    </div>
  );
};

export default ChatMessage;
