import React, { useCallback, useMemo, useState } from 'react';
import { ChannelType } from '../../../libraries/Models';

export type SidebarMainViewType = {
  className?: string;
  currentId?: number;
  channels?: ChannelType[];
  clickToSidebarItem?: (id: number, teamId?: string) => void;
};

const SidebarMainView: React.FC<SidebarMainViewType> = React.memo(({ className, channels, currentId, clickToSidebarItem }) => {
  const [isSelected, setSelected] = useState<number | null>(currentId ?? null);

  const pathname = window.location.pathname;
  
  const [teamId, channelId, threadId] = useMemo(() => {
    const segments = pathname.split('/').filter(Boolean);
    return [segments[1], segments[2], segments[3]];
  }, [pathname]);

  const clickToBtn = useCallback((id: number, teamId?: string) => {
    setSelected(id);
    if (clickToSidebarItem) {
      clickToSidebarItem(id, teamId);
    }
  }, [clickToSidebarItem]);

  const handleLogout = () => {
    document.cookie = `data=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=.pbx.team; path=/;`;
    window.location.href = `https://auth.pbx.team`;
  };

  const getColorByUUID = useCallback((uuid: string) => {
    try {
      let hash = 0;
      const cleanUUID = uuid.replace(/-/g, '');

      for (let i = 0; i < cleanUUID.length; i++) {
        hash = cleanUUID.charCodeAt(i) + ((hash << 5) - hash);
        hash = hash & hash;
      }

      let color = '#';
      for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xFF;
        color += ('00' + value.toString(16)).slice(-2);
      }
      return color;
    } catch (error) {
      return `#${Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0')}`;
    }
  }, []);

  return (
    <div className="flex flex-col w-[94px] h-full bg-[#4E4963] items-center">
      <div className="flex flex-col w-full overflow-y-scroll scrollbar-hide lg:h-[80vh] max-lg:h-[80vh]">
        {channels?.map((channel: ChannelType, index: number) => (
          <div
            key={channel.id}
            onClick={() => clickToBtn(Number(channel.id), channel.team_id)}
            className={`flex-col w-full min-h-[67px] h-[67px] items-center ${index === 0 ? "mt-0" : ""} justify-center ${
              isSelected === Number(channel.id) && String(teamId) === String(channel.team_id)
                ? 'bg-[#262330]'
                : 'hover:bg-[#EFEEF5]/20'
            } ${channel.is_hidden ? "hidden" : "flex"}`}
          >
            <div className="relative flex">
              <div className="flex h-[45px] w-[45px] justify-center items-center">
                {channel.icon ? (
                  <img className="m-3" alt="" width={45} height={45} src={`/${channel.icon}`} />
                ) : (
                  <div
                    style={{ backgroundColor: getColorByUUID(channel.id + "" + channel.team_id) }}
                    className={`w-[45px] h-[45px] rounded-full`}
                  ></div>
                )}
              </div>
            </div>
            <div className="flex h-3 text-[10px] font-normal font-['Roboto'] text-[#ffffff]">
              {channel.name}
            </div>
          </div>
        ))}
      </div>
      <div className="flex lg:h-[10vh] w-full justify-center items-center">
        <img onClick={handleLogout} className="flex fill-white cursor-pointer" alt="Logout" src={'/logout.svg'} width={35} height={35} />
      </div>
    </div>
  );
});

SidebarMainView.displayName = 'SidebarMainView';
export default SidebarMainView;
