import { useSocket } from "../../../wsprovider";
import Checkbox from "../../Checkbox";
import Dropdown from "../../Dropdown";
import Modal from "../../Modal";
import { ChannelType } from "../../../libraries/Models";
import { useCallback, useState } from "react";
import { toast, Flip } from "react-toastify";
import ProfileEditor from "../profile/ProfileContainer";
import React from "react";
import ChannelSettings from "./ChannelSettings";

export type BurgerContainerType = {};

export type HiddenChannels = {
    id: number;
    teamId: string;
};

const BurgerContainer: React.FC<BurgerContainerType> = () => {
    const { toggleHideChannel, createChannelAction, channels, tokens, decodedTokens, users } = useSocket();
    const [channel, setChannel] = useState<ChannelType | undefined>(undefined);
    const [isOpenCreateChannel, setOpenCreateChannelModal] = useState(false);
    const [currentChannel, setCurrentChannel] = useState<ChannelType | undefined>(undefined);
    const [pickedTeamId, setPickedTeamId] = useState('');
    const [isOpenProfile, setOpenProfile] = useState(false);
    const [currentTeamId, setCurrentTeamId] = useState('');
    const [selectedChannelType, setSelectedChannelType] = useState<string>('chat');

    // Опции для типа канала
    const channelTypeOptions = [
        { id: 'chat', name: 'Chat' },
        { id: 'tg_bot', name: 'Telegram Bot' },
        { id: 'selarti', name: 'Selarti task' }
    ];

    const createChannel = useCallback(() => {
        const newChannel: ChannelType = {
            name: channel?.name || "",
            type: selectedChannelType,
            is_public: channel?.is_public == true,
            team_id: pickedTeamId!,
        };
        createChannelAction(newChannel, pickedTeamId!);
        setOpenCreateChannelModal(false);
        setChannel(undefined);
    }, [channel, pickedTeamId, selectedChannelType, createChannelAction]);

    const handleOnChangeChannelTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.currentTarget.value;
        setChannel((prev) => ({ ...prev!, name: value }));
    };

    const handleOpenProfile = (teamId: string) => {
        setCurrentTeamId(teamId);
        setOpenProfile(true);
    };

    return (
        <div className="flex flex-col gap-3 overflow-y-scroll overflow-x-hidden h-[80vh] w-full scrollbar-hide">
            <div className="flex flex-row w-full h-9 mb-0 items-center">
                {currentChannel && (
                    <img
                        onClick={() => setCurrentChannel(undefined)}
                        className="mr-3 mt-3 mb-3 fill-[#716C84]"
                        alt=""
                        width={15}
                        height={15}
                        src={'/arrow-left.svg'}
                    />
                )}
                Channels settings
            </div>

            {currentChannel ? (
                <ChannelSettings currentChannel={currentChannel} />
            ) : (
                tokens?.map(team => (
                    <div key={team.team_id} className="flex flex-col gap-3">
                        <div className="flex flex-row font-medium text-lg">
                            <div className="flex flex-1">
                                {team.team_name + `${decodedTokens?.[team.team_id ? team.team_id.toString() : ''].is_admin ? " - admin" : ""}`}
                            </div>
                            <img
                                onClick={() => handleOpenProfile(team.team_id)}
                                className="flex mr-3 mt-3 mb-3 fill-[#716C84]"
                                alt=""
                                width={20}
                                height={20}
                                src={'/settings.png'}
                            />
                        </div>
                        {channels?.filter(item => item.team_id === team.team_id).map(channel => (
                            <div key={channel.id} className={`flex group flex-row ml-3 items-center gap-2 hover:text-blue`}>
                                <img
                                    onClick={() => toggleHideChannel(channel.id!, channel.team_id)}
                                    src={channel.is_hidden ? "/eye_is_hidden.png" : "/hide.png"}
                                    alt="hide"
                                    className={`w-[14px] h-[14px] ${channel.is_hidden ? "flex" : "hidden group-hover:block"}`}
                                />
                                <div onClick={() => {
                                    if (!channel.is_admin && !users?.find(i => String(i.id) === String(decodedTokens?.[team.team_id ? team.team_id.toString() : '']?.user_id))?.is_admin) {
                                        toast.error("You are not admin of this channel", {
                                            position: "top-center",
                                            theme: "colored",
                                            transition: Flip,
                                        });
                                        return;
                                    }
                                    setCurrentChannel(channel);
                                }}>
                                    {channel.name}
                                </div>
                            </div>
                        ))}
                        {users?.find(i => String(i.id) === String(decodedTokens?.[team.team_id ? team.team_id.toString() : '']?.user_id))?.is_admin && (
                            <div onClick={() => {
                                setPickedTeamId(team.team_id);
                                setOpenCreateChannelModal(true);
                            }} className="flex ml-3 text-blue">
                                add +
                            </div>
                        )}
                    </div>
                ))
            )}
            <Modal isOpen={isOpenProfile} onClose={() => setOpenProfile(false)}>
                <ProfileEditor teamId={currentTeamId} />
            </Modal>

            <Modal isOpen={isOpenCreateChannel} onClose={() => setOpenCreateChannelModal(false)}>
                <div className="flex flex-col m-5">
                    <div className="text-[24px] font-normal font-['Roboto'] text-[#797199]">Создание канала</div>
                    <input
                        className="w-full [border:none] [outline:none] bg-ghostwhite self-stretch h-[43px] flex flex-row items-start justify-start pt-4 px-3 pb-[17px] box-border font-h1 text-sm text-darkgray my-2 rounded-md"
                        placeholder="Название канала"
                        type="text"
                        name="title"
                        value={channel?.name || ""}
                        onChange={handleOnChangeChannelTitle}
                    />
                    <Dropdown
                        options={channelTypeOptions}
                        selectedId={selectedChannelType}
                        onSelect={(id) => setSelectedChannelType(id)}
                    />
                    {users?.filter(i => String(i.id) === String(decodedTokens?.[pickedTeamId]?.user_id))[0]?.is_admin && (
                        <Checkbox label="Приватный" onChange={(isChecked) => setChannel((prev) => ({ ...prev!, is_public: !isChecked }))} />
                    )}
                    <button
                        className="flex justify-center items-center text-sm text-white text-center bg-blue p-3 rounded-md mt-3"
                        onClick={createChannel}
                    >
                        Создать
                    </button>
                </div>
            </Modal>
        </div>
    );
};

export default BurgerContainer;
