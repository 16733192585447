import React, { useState } from 'react';

interface DropdownOption {
  id: string;
  name: string;
  color?: string;
}

interface DropdownProps {
  options: DropdownOption[];
  selectedId: string;
  onSelect: (selectedId: string) => void;
}

const Dropdown: React.FC<DropdownProps> = ({ options, selectedId, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);

  // Получаем выбранную опцию
  const selectedOption = options.find(option => option.id === selectedId) || options[0];

  // Функция для обработки выбора элемента
  const handleSelect = (id: string) => {
    onSelect(id);
    setIsOpen(false); // Закрываем дропдаун после выбора
  };

  return (
    <div className="relative inline-block text-left">
      <div>
        <button
          type="button"
          className="inline-flex justify-between items-center w-full px-3 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          style={{ color: selectedOption.color || "text-gray-700" }}
          onClick={() => setIsOpen(!isOpen)}
        >
          {selectedOption.name}
          <svg
            className="-mr-1 ml-2 h-5 w-5 text-gray-500"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>

      {isOpen && (
        <div className="absolute left-0 mt-2 w-full rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 z-10">
          <div className="py-1">
            {options.map(option => (
              <button
                key={option.id}
                onClick={() => handleSelect(option.id)}
                className={`block w-full text-left px-4 py-2 text-sm text-gray-700 ${
                  selectedId === option.id ? 'bg-gray-100 font-semibold' : ''
                } hover:bg-gray-100 focus:outline-none`}
              >
                {option.name}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
