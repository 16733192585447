import React, { useState } from 'react';

interface CheckboxProps {
  label: string;
  checked?: boolean;
  onChange?: (checked: boolean) => void;
}

const Checkbox: React.FC<CheckboxProps> = ({ label, checked = false, onChange }) => {
  const [isChecked, setIsChecked] = useState(checked);

  const handleCheckboxChange = () => {
    const newCheckedState = !isChecked;
    setIsChecked(newCheckedState);
    if (onChange) {
      onChange(newCheckedState);
    }
  };

  return (
    <label className="flex items-center space-x-2">
      <input 
        type="checkbox" 
        checked={isChecked} 
        onChange={handleCheckboxChange}
        className="form-checkbox h-5 w-5 text-blue-600"
      />
      <span>{label}</span>
    </label>
  );
};

export default Checkbox;
