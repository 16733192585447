import React from 'react';
import ReactDOM from 'react-dom';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (

    ReactDOM.createPortal(
    <div className="h-screen w-screen fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[50]">
      <div className="bg-white p-6 rounded shadow-lg max-w-lg w-full relative z-[50]">
        <button 
          onClick={onClose}
          className="absolute top-0 right-0 mt-4 mr-4 text-lg text-gray-500 hover:text-gray-700 rounded-full w-6 h-6 border-none"
        >
          &times;
        </button>
        <div>{children}</div>
      </div>
    </div>,
    document.body
    )
  );
};

export default Modal;
