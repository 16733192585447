import React, { useState, useEffect, ChangeEvent } from "react";
import { ChannelType, UserType } from "../../../libraries/Models";
import Dropdown from "../../Dropdown";
import { useSocket } from "../../../wsprovider";
import ColorPicker from "../../ColorPicker";
import Checkbox from "../../Checkbox";
import Modal from "../../Modal";
import { Flip, toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

export type ChannelSettingsType = {
  currentChannel: ChannelType;
};

export type ChannelProcesses = {
  id: string;
  name: string;
  color: string;
  status: string;
  isEditing: boolean;
};

export type ChannelMembers = {
  id?: string;
  name: string;
  role: string;
  isEditing: boolean;
};

export type ChannelSettings = {
  processes: ChannelProcesses[] | undefined;
  members: ChannelMembers[] | undefined;
  bot_key?: string;
  selarti_company_id?: string;
  selarti_task_id?: string;
};

const ChannelSettings: React.FC<ChannelSettingsType> = ({ currentChannel }) => {
  const [channel, setChannel] = useState<ChannelType | undefined>(currentChannel);
  const [isProcessUsed, setIsProcessUsed] = useState(false);
  const [isPublic, setIsPublic] = useState(currentChannel.is_public || false);
  const [editableTitle, setEditableTitle] = useState(currentChannel.name);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [isOpenMemberAdd, setOpenMemberAddModal] = useState(false);
  const { users, updateChannelIsPublicAction, updateChannelNameAction, updateChannelSettingsAction, updateChannelIsProcessAction, getSelartiCompanies, getSelartiTasks, channels } = useSocket();
  const [searchValue, setSearchValue] = useState("");
  const [botKey, setBotKey] = useState("");
  const [companies, setCompanies] = useState<{ id: string, name: string }[]>([]);
  const [tasks, setTasks] = useState<{ id: string, name: string }[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<string | null>(null);
  const [selectedTask, setSelectedTask] = useState<string | null>(null);
  const [processStates, setProcessStates] = useState<ChannelProcesses[]>([]);
  const [members, setMembers] = useState<ChannelMembers[]>([]);
  const [searchedMembers, setSearchedMembers] = useState<ChannelMembers[]>([]);

  useEffect(() => {
    let updatedChannel = channels?.find(item => item.id === currentChannel.id);
    setChannel(updatedChannel);
    const settings = JSON.parse(updatedChannel?.settings || "{}") as ChannelSettings;

    if (settings.processes) {
      setProcessStates(settings.processes);
      setIsProcessUsed(channel?.is_process === true);
    }
    if (settings.members) {
      setMembers(settings.members);
    }
    if (settings.bot_key) {
      setBotKey(settings.bot_key);
      if (currentChannel.type === 'selarti') {
        getSelartiCompanies(currentChannel.id!, currentChannel.team_id).then(companyList => setCompanies(companyList));
        if (settings.selarti_company_id) {
          getSelartiTasks(currentChannel.id!, currentChannel.team_id, settings.selarti_company_id).then(tasks => setTasks(tasks));
        }
      }
    }
    if (settings.selarti_company_id) {
      setSelectedCompany(settings.selarti_company_id);
    }
    if (settings.selarti_task_id) {
      setSelectedTask(settings.selarti_task_id);
    }
  }, [channels, channel, getSelartiCompanies, getSelartiTasks, currentChannel.settings, currentChannel.id, currentChannel.type, currentChannel.team_id]);

  const updateChannelSettings = async (updatedProcesses: ChannelProcesses[], updatedMembers: ChannelMembers[], updatedBotKey: string, companyId?: string, taskId?: string) => {
    const updatedSettings = {
      ...JSON.parse(channel?.settings || '{}'),
      processes: updatedProcesses,
      members: updatedMembers,
      bot_key: updatedBotKey,
      selarti_company_id: companyId || selectedCompany,
      selarti_task_id: taskId || selectedTask
    };

    return await updateChannelSettingsAction(channel?.id!, JSON.stringify(updatedSettings), channel!.team_id);
  };

  const handleBotKeyChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const newBotKey = e.target.value;
    setBotKey(newBotKey);

    const updated = await updateChannelSettings(processStates, members, newBotKey);

    if (!updated && newBotKey) {
      try {
        const companyList = await getSelartiCompanies(channel!.id!, channel!.team_id);
        setCompanies(companyList);
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    }
  };

  const handleCompanySelect = async (companyId: string) => {
    setSelectedCompany(companyId);
    setSelectedTask(null);
    updateChannelSettings(processStates, members, botKey, companyId);

    if (companyId && botKey) {
      try {
        const taskList = await getSelartiTasks(channel!.id!, channel!.team_id, companyId);
        setTasks(taskList);
      } catch (error) {
        console.error("Error fetching tasks:", error);
      }
    }
  };

  const handleTaskSelect = (taskId: string) => {
    setSelectedTask(taskId);
    updateChannelSettings(processStates, members, botKey, selectedCompany || "", taskId);
  };

  const addProcess = () => {
    const newProcess = { id: uuidv4(), name: `Новый процесс ${processStates.length + 1}`, color: "#808080", status: "open", isEditing: false };
    const updatedProcesses = [...processStates, newProcess];
    setProcessStates(updatedProcesses);
    updateChannelSettings(updatedProcesses, members, botKey);
  };

  const removeProcess = (index: number) => {
    const updatedProcesses = processStates.filter((_, i) => i !== index);
    setProcessStates(updatedProcesses);
    updateChannelSettings(updatedProcesses, members, botKey);
  };

  const handleProcessStatusChange = (index: number, selectedId: string) => {
    const updatedStates = [...processStates];
    updatedStates[index].status = selectedId === "1" ? "open" : "close";
    setProcessStates(updatedStates);
    updateChannelSettings(updatedStates, members, botKey);
  };

  const handleProcessColorChange = (index: number, newColor: string) => {
    const updatedStates = [...processStates];
    updatedStates[index].color = newColor;
    setProcessStates(updatedStates);
    updateChannelSettings(updatedStates, members, botKey);
  };

  const enableProcessNameEditing = (index: number) => {
    const updatedStates = processStates.map((process, i) => ({
      ...process,
      isEditing: i === index
    }));
    setProcessStates(updatedStates);
  };

  const handleProcessNameBlur = (index: number) => {
    const updatedStates = processStates.map((process) => ({
      ...process,
      isEditing: false,
    }));
    setProcessStates(updatedStates);
    updateChannelSettings(updatedStates, members, botKey);
  };

  const handleProcessNameChange = (index: number, newName: string) => {
    const updatedStates = [...processStates];
    updatedStates[index].name = newName;
    setProcessStates(updatedStates);
  };

  const addMember = (data: ChannelMembers) => {
    const newMember = { id: data.id, name: data.name, role: "member", isEditing: false };
    const updatedMembers = [...members, newMember];
    setMembers(updatedMembers);
    updateChannelSettings(processStates, updatedMembers, botKey);
    toast.success("User added", {
      position: "top-center",
      theme: "colored",
      transition: Flip,
    });
  };

  const removeMember = (index: number) => {
    const updatedMembers = members.filter((_, i) => i !== index);
    setMembers(updatedMembers);
    updateChannelSettings(processStates, updatedMembers, botKey);
  };

  const enableMemberNameEditing = (index: number) => {
    const updatedMembers = members.map((member, i) => ({
      ...member,
      isEditing: i === index
    }));
    setMembers(updatedMembers);
  };

  const handleMemberNameBlur = (index: number) => {
    const updatedMembers = members.map((member) => ({
      ...member,
      isEditing: false,
    }));
    setMembers(updatedMembers);
    updateChannelSettings(processStates, updatedMembers, botKey);
  };

  const handleMemberNameChange = (index: number, newName: string) => {
    const updatedMembers = [...members];
    updatedMembers[index].name = newName;
    setMembers(updatedMembers);
  };

  const toggleMemberRole = (index: number) => {
    const updatedMembers = [...members];
    updatedMembers[index].role = updatedMembers[index].role === 'member' ? 'admin' : 'member';
    setMembers(updatedMembers);
    updateChannelSettings(processStates, updatedMembers, botKey);
  };

  const handleTitleClick = () => {
    setIsEditingTitle(true);
  };

  const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEditableTitle(e.target.value);
  };

  const handleTitleBlur = () => {
    setIsEditingTitle(false);
    updateChannelNameAction(channel!.id!, editableTitle, channel!.team_id);
  };

  const togglePrivate = () => {
    setIsPublic(!isPublic);
    updateChannelIsPublicAction(channel!.id!, !isPublic, channel!.team_id);
  };

  const toggleProcess = () => {
    setIsProcessUsed(!isProcessUsed);
    updateChannelIsProcessAction(channel!.id!, !isProcessUsed, channel!.team_id);
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.value;
    setSearchValue(name);
    if (name === '') {
      setSearchedMembers([]);
      return;
    }
    const filteredUsers = users?.filter(user =>
      user.username.toLowerCase().includes(name) || user.fullname.toLowerCase().includes(name)
    ).map(user => ({
      id: user.id,
      name: user.fullname || user.nicname,
      role: user.is_admin ? 'admin' : 'member',
      isEditing: false
    }));
    setSearchedMembers(filteredUsers!);
  };

  const goToAdmin = () => {
    window.location.href = `https://auth.pbx.team/admin`;
  };

  return (
    <div className="flex flex-col w-full scrollbar-hide">
      <div className="flex mb-0">
        {isEditingTitle ? (
          <input
            className="flex text-sm text-gray-600 mb-4 px-2 py-1 h-6"
            value={editableTitle}
            onChange={handleTitleChange}
            onBlur={handleTitleBlur}
            autoFocus
          />
        ) : (
          <h3 className="flex text-sm text-gray-600 mb-4 cursor-pointer h-6 underline" onClick={handleTitleClick}>
            {editableTitle}
          </h3>
        )}
      </div>
      { currentChannel.type === 'tg_bot' && (
        <div className="flex flex-row mb-4 items-center">
        <label className="flex text-sm mr-2">Bot Key:</label>
        <input
          className="flex flex-1 text-sm text-gray-600 px-2 py-1 border border-gray-300 rounded"
          value={botKey}
          onChange={handleBotKeyChange}
          placeholder="Введите ключ бота"
        />
      </div>)
      }
      
      {currentChannel.type === 'selarti' && (
          <>
            <div className="flex flex-row mb-4 items-center">
              <label className="flex text-sm mr-2">Selarti Token:</label>
              <input
                className="flex flex-1 text-sm text-gray-600 px-2 py-1 border border-gray-300 rounded"
                value={botKey}
                onChange={handleBotKeyChange}
                placeholder="Введите токен Selarti"
              />
            </div>

            {botKey && companies.length > 0 && (
              <div className="mb-4">
                <Dropdown
                  options={companies}
                  selectedId={selectedCompany || ""}
                  onSelect={handleCompanySelect}
                />
              </div>
            )}

            {selectedCompany && tasks.length > 0 && (
              <div className="mb-4">
                <Dropdown
                  options={tasks.map(task => ({ id: task.id, name: task.name }))}
                  selectedId={selectedTask || ""}
                  onSelect={handleTaskSelect}
                />
              </div>
            )}

            {/* Вывод ссылки и кнопка копирования */}
            <div className="flex flex-row items-center mb-4">
              <span className="text-sm text-blue-600 truncate mr-2" style={{ maxWidth: '70%' }}>
                {`https://s2.pbx.team/api/webhook/selarti?teamid=${currentChannel.team_id}&channelid=${currentChannel.id}`}
              </span>
              <button
                className="px-2 py-1 text-sm bg-blue text-white rounded border-none hover:bg-blue focus:outline-none"
                onClick={() => {
                  navigator.clipboard.writeText(`https://s2.pbx.team/api/webhook/selarti?teamid=${currentChannel.team_id}&channelid=${currentChannel.id}`);
                  alert('Copied');
                }}
              >
                Copy
              </button>
            </div>
          </>
        )}


      <div className="flex justify-between items-center mb-4">
        <span className="text-sm">Use as process</span>
        <button
          onClick={toggleProcess}
          className={`relative inline-flex items-center h-6 rounded-full w-11 transition-colors border-none focus:outline-none ${isProcessUsed ? "bg-green-500" : "bg-gray-300"}`}
        >
          <span
            className={`inline-block w-5 h-5 transform bg-white rounded-full  transition-transform ${isProcessUsed ? "translate-x-4" : "-translate-x-1"}`}
          ></span>
        </button>
      </div>

      {isProcessUsed && processStates.map((process, index) => (
        <div key={process.name} className="flex justify-between items-center mb-2">
          <div className="flex flex-1 items-center space-x-2">
            <ColorPicker color={process.color} onChange={(newColor) => handleProcessColorChange(index, newColor)} />

            {process.isEditing ? (
              <input
                className="text-sm border border-gray-300 rounded px-2 py-1 focus:outline-none focus:border-blue-500"
                value={process.name}
                onChange={(e) => handleProcessNameChange(index, e.target.value)}
                onBlur={() => handleProcessNameBlur(index)}
                autoFocus
              />
            ) : (
              <span
                className="text-sm cursor-pointer"
                onClick={() => enableProcessNameEditing(index)}
              >
                {process.name}
              </span>
            )}
          </div>
          <Dropdown
            options={[{ id: "1", name: "open" }, { id: "2", name: "close" }]}
            selectedId={process.status === 'open' ? "1" : "2"}
            onSelect={(selectedId: string) => handleProcessStatusChange(index, selectedId)}
          />
          <button className="flex items-center justify-center border-none bg-transparent" onClick={() => removeProcess(index)}><img src="/delete.png" className="flex w-5 h-5" /></button>
        </div>
      ))}

      {isProcessUsed && <button className="flex w-11 text-blue text-sm border-none mb-4 bg-transparent" onClick={addProcess}>add+</button>}

      <div className="flex justify-between items-center mb-4">
        <span className="text-sm">Private</span>
        <button
          onClick={togglePrivate}
          className={`relative inline-flex items-center border-none h-6 rounded-full w-11 transition-colors focus:outline-none ${isPublic ? "bg-gray-300" : "bg-green-500"}`}
        >
          <span
            className={`inline-block w-5 h-5 transform bg-white rounded-full transition-transform ${isPublic ? "-translate-x-1" : "translate-x-4"}`}
          ></span>
        </button>
      </div>

      <h4 className="text-sm font-semibold mb-2">Members</h4>
      {members.map((member, index) => (
        <div key={member.name} className="flex justify-between items-center mb-2">
          <div className="flex flex-1 items-center space-x-2">
            <img
              src={`https://i.pravatar.cc/150?u=${member.name}`}
              alt={member.name}
              className="w-8 h-8 rounded-full"
            />

            {member.isEditing ? (
              <input
                className="text-sm border border-gray-300 rounded px-2 py-1 focus:outline-none focus:border-blue-500"
                value={member.name}
                onChange={(e) => handleMemberNameChange(index, e.target.value)}
                onBlur={() => handleMemberNameBlur(index)}
                autoFocus
              />
            ) : (
              <span
                className="text-sm cursor-pointer"
                onClick={() => enableMemberNameEditing(index)}
              >
                {member.name}
              </span>
            )}
          </div>
          <span className="flex text-sm text-gray-500 cursor-pointer" onClick={() => toggleMemberRole(index)}>
            {member.role}
          </span>
          <button className="flex items-center justify-center bg-transparent border-none" onClick={() => removeMember(index)}><img src="/delete.png" className="flex w-5 h-5" /></button>
        </div>
      ))}
      <button className="flex w-11 text-blue text-sm mb-4 bg-transparent border-none" onClick={() => setOpenMemberAddModal(true)}>add+</button>
      <Modal isOpen={isOpenMemberAdd} onClose={() => setOpenMemberAddModal(false)}>
        <div className="flex flex-col m-5 gap-3">
          <div className="text-[24px] font-normal font-['Roboto'] text-[#797199]">Add member</div>
          <input
            className="w-full mb-4 [border:none] [outline:none] bg-ghostwhite self-stretch h-[43px] flex flex-row items-start justify-start pt-4 px-3 pb-[17px] box-border font-h1 text-sm text-darkgray my-2 rounded-md"
            placeholder="Search member"
            type="text"
            name="title"
            value={searchValue}
            onChange={handleSearch}
          />
          {searchedMembers.map(item => (
            <div key={item.id} onClick={() => addMember({ name: item.name, id: item.id, role: '', isEditing: false })} className="flex flex-row h-[50px] w-full items-center gap-3">
              <div className="flex bg-green-500 min-w-[50px] h-[50px] rounded-full" />
              <div className="flex flex-1 flex-col h-full gap-1 max-w-[300px]">
                <div className="text-[#000000] font-normal text-[20px] whitespace-nowrap overflow-hidden text-ellipsis">{item.name}</div>
                <div className="text-[#726F77] font-normal text-[20px]">{item.role}</div>
              </div>
            </div>
          ))}
          <button
            className="flex justify-start items-center text-sm text-blue text-center bg-transparent mt-4 border-none"
            onClick={goToAdmin}
          >
            Invite new user +
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default ChannelSettings;
